import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  Button,
  InputGroup,
  Row,
  Col,
  Image,
} from "react-bootstrap";

import HelperService from "../../Services/HelperService";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddMoney = () => {
  const [amount, setAmount] = useState("");
  const [mobileNumber, setMobileNumber] = useState<any>();
  const navigate = useNavigate();

  const recommendedAmounts = [500, 1000, 2000];

  const hexString = localStorage.getItem("dataHex");

  useEffect(() => {
    if (hexString && hexString.match) {
      console.log("hexString", hexString);

      const jsonString =
        hexString
          .match(/.{1,2}/g)
          ?.map((byte) => String.fromCharCode(parseInt(byte, 16)))
          .join("") || "";
      console.log("hexString", jsonString);
      const dataObject = JSON.parse(jsonString);

      setMobileNumber(dataObject.uuId6);
    }
  }, []);

  const onAddAmount = () => {
    const options = {
      key: "rzp_test_rkXObZBx8fr67e",
      amount: parseFloat(amount) * 100, // Convert amount to number and multiply by 100
      name: "Energi Bharat",
      description: "Test Transaction",
      handler: async (response: any) => {
        console.log("response", response);

        const verifyData = {
          mobile_number: mobileNumber,
          wallet_amount: amount,
        };
        try {
          const verificationResponse: any = await WebService.putAPI({
            action: `add-wallet`,
            body: verifyData,
          });

          if (verificationResponse.success) {
            toast.success("Payment Success Added to Wallet.");
            
            navigate("/payment")  
          } else {
            toast.error("Payment Added failed!");
          }
        } catch (verificationError) {
          console.error("Payment verification error:", verificationError);
          alert("Payment verification failed!");
        }
      },

      theme: {
        color: "#1B3275",
      },
    };

    // Open Razorpay Checkout

    console.log("options", options);

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  return (
    <>
      <div className="container bodywhite">
        <div className="headerbg" style={{ height: "100px" }}>
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <h4 className="comfortaa">&nbsp; Payment</h4>
            </div>
            <div>
              <Image
                src="/assets/img/logo.svg"
                width={85}
                height={53}
                alt="logo"
                className="img-fluid"
              />
            </div>
          </div>
        </div>

        <div className="whitebox">
          <Container
            className="p-4"
            style={{
              maxWidth: "500px",
              height: "350px",
              top: "50%",

              backgroundColor: "#fff",
              borderRadius: "10px",
            }}
          >
            <h5 className="mb-3"> Add Money</h5>

            {/* Input Field */}
            <Form.Group className="mb-3">
              <Form.Label>Enter Amount</Form.Label>
              <InputGroup>
                <InputGroup.Text>₹</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Enter amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  onKeyPress={HelperService.allowOnlyNumericValue10}
                />
              </InputGroup>
            </Form.Group>

            {/* Recommended Amount Buttons */}
            <div className="mb-3">
              <span className="text-muted">Recommended</span>
              <Row className="mt-2">
                {recommendedAmounts.map((amt: any) => (
                  <Col key={amt} xs="4">
                    <Button
                      variant="light"
                      className="w-100"
                      onClick={() => setAmount(amt)}
                    >
                      ₹{amt}
                    </Button>
                  </Col>
                ))}
              </Row>
            </div>

            {/* Proceed Button */}
            <Button
              variant="success"
              className="w-100"
              onClick={() => onAddAmount()}
              disabled={!amount}
            >
              Proceed To Pay
            </Button>
          </Container>
        </div>
      </div>
      {/* <div className="d-flex justify-content-center align-items-center vh-100">
    
      </div> */}
    </>
  );
};

export default AddMoney;
