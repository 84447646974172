import {
  Routes,
  Route,
  Navigate,
  RouteProps,
  RoutesProps,
} from "react-router-dom";

import React, { Suspense, useEffect } from "react";
import { useState } from "react";
import ProtectedRoute from "./ProtectedRoute";
import Main from "../components/Main";
import AddMoney from "../components/Payment/AddMoney";

const Navigation = () => {
  interface ProtectedRouteProps extends RoutesProps {
    authenticationPath: string;
  }
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    authenticationPath: "/login",
  };

  const Login = React.lazy(() => import("../components/Login/Login"));

  const Dashboard = React.lazy(
    () => import("../components/Dashboard/Dashboard")
  );

  const Booking_Details = React.lazy(
    () => import("../components/Booking_Details/Booking_Details")
  );

  const Available_Slots = React.lazy(
    () => import("../components/Available_Slots/Available_Slots")
  );
  const Otp = React.lazy(() => import("../components/Otp/Otp"));

  const Payment = React.lazy(() => import("../components/Payment/Payment"));

  const Checkout = React.lazy(() => import("../components/Checkout/Checkout"));

  const Instructions = React.lazy(
    () => import("../components/Instructions/Instructions")
  );

  const Charging = React.lazy(() => import("../components/Charging/Charging"));
  const PrivacyPolicy = React.lazy(
    () => import("../components/Privacy Policy/privacy-policy")
  );
  const Charging_start = React.lazy(
    () => import("../components/Charging_start/Charging_start")
  );
  const Booking_Complete = React.lazy(
    () => import("../components/Booking_Complete/Booking_Complete")
  );

  return (
    <>
      <div id="main-wraper">
        <Routes>
          <Route path="/login" element={<Suspense fallback={<></>}> <Login /> </Suspense>}/>
          <Route path="/" element={<Navigate replace to="/dashboard" />} />
          <Route path="/"
            element={ <ProtectedRoute isAuthenticated={true} {...defaultProtectedRouteProps} outlet={<Main />} />} >
            <Route path="/dashboard"
              element={
                <Suspense fallback={<></>}>
                  <Dashboard />
                </Suspense>
              }
            />
            <Route  path="/bookingdetails"
              element={
                <Suspense fallback={<></>}>
                  <Booking_Details />
                </Suspense>
              }
            />
            <Route
              path="/availableslots"
              element={
                <Suspense fallback={<></>}>
                  <Available_Slots />
                </Suspense>
              }
            />
            <Route
              path="/otp"
              element={
                <Suspense fallback={<></>}>
                  <Otp />
                </Suspense>
              }
            />
            <Route
              path="/payment"
              element={
                <Suspense fallback={<></>}>
                  <Payment />
                </Suspense>
              }
            />
            <Route
              path="/checkout"
              element={
                <Suspense fallback={<></>}>
                  <Checkout />
                </Suspense>
              }
            />

            <Route
              path="/instructions"
              element={
                <Suspense fallback={<></>}>
                  <Instructions />
                </Suspense>
              }
            />
            <Route
              path="/charging"
              element={
                <Suspense fallback={<></>}>
                  <Charging />
                </Suspense>
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <Suspense fallback={<></>}>
                  <PrivacyPolicy />
                </Suspense>
              }
            />
            <Route
              path="/chargingstart"
              element={
                <Suspense fallback={<></>}>
                  <Charging_start />
                </Suspense>
              }
            />
            <Route
              path="/bookingcomplete"
              element={
                <Suspense fallback={<></>}>
                  <Booking_Complete />
                </Suspense>
              }
            />
              <Route
              path="/add-money"
              element={
                <Suspense fallback={<></>}>
                  <AddMoney />
                </Suspense>
              }
            />
          </Route>
        </Routes>
      </div>
    </>
  );
};

export default Navigation;
