import moment from "moment";
// import { ObjectType } from "typescript";
import WebService from "./WebService";
import { toast } from "react-toastify";
const HelperService = {
  getFormatedDateForDetail(dt: any, format?: any) {
    var stillUtc = moment.utc(dt).toDate();
    if (dt) {
      if (format) {
        return moment(stillUtc).local().format(format);
      } else {
        return moment(stillUtc).local().format(
          (localStorage.getItem("date_format") || "DD-MM-YYYY") + " hh:mm A"
        );
      }
    }
  },

  
  maxNumber(e: any, maxNumber: number) {
    if (maxNumber && e.currentTarget.value.length > maxNumber) {
      e.preventDefault();
      return false;
    }
  },

  allowOnlyNumericValue10(e: any) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode !== 8) {
      e.preventDefault();
      return false;
    }

    if (e.currentTarget.value.length > 9) {
      e.preventDefault();
      return false;
    }
  },

  allowOnlyNumericValueAndString(e: any) {
    var numbers = /^[0-9A-Za-z]$/;
    if (!e.key.match(numbers) && e.keyCode !== 8) {
      e.preventDefault();
      return false;
    }

    if (e.currentTarget.value.length >= 15) {
      e.preventDefault();
      return false;
    }
  },
  isValidGST: (gst:string) => {
    const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}Z[0-9A-Z]{1}$/;
    return gstRegex.test(gst);
  },

  getWebSocketIP() {
    return "wss://ws.energibharat.com/EVB-P20261797";
    // return "ws://localhost:8088";
    // return "ws://ec2-13-202-37-64.ap-south-1.compute.amazonaws.com:8088/EVB-P20261797"
  },

  approxTimeText(unit: number, kwh: number): string {

    if(kwh > 80) {
      kwh = 80;
    }
    const timeInHours = unit / kwh;
    
    const timeInMinutes = timeInHours * 60;
  
    if (timeInMinutes < 60) {
      return `${Math.round(timeInMinutes)} Min`;
    } else  if (timeInMinutes == 60) {
       return `1 Hour`
    } else {
      const hours = Math.floor(timeInMinutes / 60);
      const minutes = Math.round(timeInMinutes % 60);
      return `${hours} Hour${hours > 1 ? 's' : ''} ${minutes} Min`;
    }
  },

  approxTime(unit: number, kwh: number): number {
    if(kwh > 80) {
      kwh = 80;
    }
    const timeInHours = unit / kwh;

    const timeInMinutes = timeInHours * 60;
    return timeInMinutes;
  }


};


export default HelperService;
